import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'

const About = (props) => (
    <Layout>
        <Helmet>
            <title>About - Forty by HTML5 UP</title>
            <meta name="description" content="About Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h3>About this app</h3>
                    </header>
                    <h4>Instructions</h4>
                    <ol>
                        <li>Choose the number of sides for the dice.</li>
                        <li>Choose how many dice of that type you want.</li>
                        <li>Choose a bonus or penalty if there is any.</li>
                        <li>Assign a description for the group of dice</li>
                        <li>Click Add to add the dice to the pool.</li>
                        <li>Click the checkboxes of the dice group you want to roll.</li>
                        <li>Click Roll Selection</li>
                    </ol>
                    <h4>Version Info</h4>
                    <ul>
                        <li>DiceRoller V1.0.2</li>
                        <li>Theme by: <a href="https://html5up.net">HTML5 UP</a></li>
                    </ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default About